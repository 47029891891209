<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNER -->
    <div id="banner" :style="{
      'background-image':
        'url(' + require('@/assets/img/about/banner.jpg') + ')'
    }">
      <div class="texto-banner container flex justify-between items-center">
        <h2 class="text-white text-5xl font-bold">Quienes Somos</h2>
        <p class="text-gray-100">Home / Quienes Somos</p>
      </div>
    </div>

    <!-- SECTION QUIENES SOMOS -->
    <section class="quienes-somos pb-10">
      <div class="grid grid-cols-2 mx-auto px-4 md:px-32 ">
        <!-- col 1 -->
        <div class="col-span-2 md:col-span-1 my-auto" data-aos="fade-right" data-aos-duration="1000">
          <h1 class="azul text-5xl font-bold leading-10 mb-5">
            ¿Quiénes somos?
          </h1>
          <p class="azul text-justify leading-5 text-sm mb-3">
            La cooperativa de ahorro y Crédito Credil desde su inicio ha buscado
            satisfacer las necesidades de sus socios y clientes, contribuyendo
            directamente a mejorar su nivel de vida, apoyándolos para que
            crezcan en su actividad económica y en el liderazgo, mediante
            servicios financieros de calidad. Nuestro compromiso social es
            construir un modelo empresarial sólido y de éxito, de propiedad de
            sus socios. La cooperativa ayuda a construir un mundo mejor,
            contribuye al desarrollo económico – sostenible del país. CREDIL TU
            FORTALEZA FINANCIERA
          </p>

          <p class="azul font-bold mt-2">Ing. Vinicio Lagua</p>
          <span class="font-thin azul">Gerente General</span>
        </div>

        <!-- col 2 -->
        <div class="col-span-2 md:col-span-1" data-aos="fade-left" data-aos-duration="1000">
          <img src="@/assets/img/about/manager.jpg" alt="gerente credil" width="500"
            class="mx-auto px-10 md:px-5 mt-24" />
        </div>
      </div>
    </section>

    <!-- SECTION DESCUBRE EL MUNDO DE LAS FINANZAS -->
    <section>
      <div class="img-banner-bg  z-0" :style="{
        'background-image':
          'url(' + require('@/assets/img/about/banner2.jpg') + ')'
      }">
        <div class="texto-principal-right" data-aos="fade-up" data-aos-duration="1000">
          <h3 class="text-white text-5xl font-bold text-center mb-6 " style="line-height: 3rem">
            Descubre el mundo <br />de las finanzas
          </h3>
          <div class="container flex justify-center">
            <!-- icono 1 -->
            <div class="icono mr-5">
              <div class="flex justify-center">
                <img src="@/assets/img/about/icono1.png" alt="logro credil" class="h-20" />
                <span class="text-white text-5xl font-bold">12</span>
              </div>
              <span class="text-white">Años de expreriencia</span>
            </div>

            <!-- icono 2 -->
            <div class="icono ml-5">
              <div class="flex justify-center">
                <img src="@/assets/img/about/icono2.png" alt="logro credil" class="h-20" />
                <span class="text-white text-5xl font-bold">19096</span>
              </div>
              <span class="text-white text-center block mx-auto">Proyectos Completados</span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- SECTION RESENA HISTORICA -->
    <section class="quienes-somos pb-10 bg-white">
      <div class="grid grid-cols-2 mx-auto px-4 md:px-32 ">
        <!-- col 1 -->
        <div class="col-span-2 md:col-span-1" data-aos="fade-right" data-aos-duration="1000">
          <img src="@/assets/img/about/team.jpg" alt="gerente crediya" width="500"
            class="mx-auto px-10 md:px-5 mt-24" />
        </div>
        <!-- col 2 -->
        <div class="col-span-2 md:col-span-1 my-auto" data-aos="fade-left" data-aos-duration="1000">
          <h1 class="azul text-3xl font-bold leading-10 mb-5">
            Reseña Histórica
          </h1>
          <p class="azul text-justify leading-5 text-sm mb-3">
            La Cooperativa de Ahorro y Crédito Credil Ltda., surge de la
            iniciativa de un grupo de 5 personas cuyo objetivo era crear una
            institución financiera que mejore la calidad de vida de las personas
            y así constituirse en el futuro como un ente promotor del desarrollo
            económico. La idea de inicio fue abrir una cooperativa que otorgue
            créditos a partir de prendas de joyas de oro en la cual según el
            valor de la prenda se otorgaba los créditos.
          </p>
          <p class="azul text-justify leading-5 text-sm mb-3">
            Luego de un arduo trabajo de búsqueda de socios fundadores se logró
            el aporte de 16 personas quienes contribuyeron y alcanzaron un
            capital de 30.000 dólares con el cual se podía formar la
            cooperativa, pero en la ciudad de Ambato no se dio la apertura de la
            misma, no obstante, la cooperativa ya funcionaba en el mercado
            mayorista como una precooperativa otorgando créditos a los pequeños
            comerciantes.
          </p>
          <p class="azul text-justify leading-5 text-sm mb-3">
            Durante el año 2008 y 2009 con reuniones diarias se optó por abrir
            la cooperativa en la provincia de Cotopaxi con la visión de abrir
            una agencia en la ciudad de Ambato.
          </p>

          <p class="azul font-bold mt-2">Ing. Vinicio Lagua</p>
          <span class="font-thin azul">Gerente General</span>
        </div>
      </div>
    </section>

    <!-- SECTION MISION VISION -->
    <section class="quienes-somos pb-10 bg-white">
      <div class="grid grid-cols-2 mx-auto px-4 md:px-32 ">
        <!-- col 1 -->
        <div class="col-span-2 md:col-span-1 m-2" data-aos="fade-right" data-aos-duration="1000">
          <div class="flex">
            <img src="@/assets/img/about/vision.png" alt="vision credil" class="h-20" />
            <div class="texto">
              <h4 class="azul font-semibold text-2xl">Visión</h4>
              <p class="text-justify azul text-sm">
                Para el año 2023 ser una entidad financiera basada en valores
                cristianos, reconocida a nivel nacional con productos y
                servicios competitivos a través de la innovación tecnológica.
              </p>
            </div>
          </div>
        </div>
        <!-- col 2 -->
        <div class="col-span-2 md:col-span-1 m-2" data-aos="fade-left" data-aos-duration="1000">
          <div class="flex">
            <img src="@/assets/img/about/mision.png" alt="vision credil" class="h-20" />
            <div class="texto">
              <h4 class="azul font-semibold text-2xl">Misión</h4>
              <p class="text-justify azul text-sm">
                Somos una Cooperativa de Ahorro y Crédito que contribuye al
                desarrollo económico – sostenible del país, a través de la
                generación de empleo, brindando productos y servicios
                financieros para mejorar la calidad de vida de nuestros socios y
                clientes en la zona centro del país.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- VALORES CORPORATIVOS -->
    <section>
      <div class="grid grid-cols-2 container my-20" data-aos="fade-up" data-aos-duration="1000">
        <!-- col 1 -->
        <div class="col-span-2 md:col-span-1 p-4 md:p-12 " style="background-color:#0a5495;">
          <div class=" mt-5 md:mt-24">
            <h4 class="text-white text-3xl mb-6 font-bold leading-10">
              Valores Corporativos
            </h4>

            <p class="text-justify text-white mb-5">
              Lo que es imposible para los hombres, es posible para Dios. Lucas
              18, 27.
            </p>
            <ul>
              <li class="text-white text-2xl mb-2 font-semibold">
                - Honestidad
              </li>
              <li class="text-white text-2xl mb-2 font-semibold">
                - Responsabilidad
              </li>
              <li class="text-white text-2xl mb-2 font-semibold">
                - Solidaridad
              </li>
              <li class="text-white text-2xl mb-2 font-semibold">
                - Lealtad
              </li>
              <li class="text-white text-2xl mb-2 font-semibold">
                - Compromiso
              </li>
              <li class="text-white text-2xl mb-2 font-semibold">
                - Equidad
              </li>
              <li class="text-white text-2xl mb-2 font-semibold">
                - Transparencia
              </li>
            </ul>
          </div>
        </div>

        <!-- col 2 -->
        <div class="col-span-2 md:col-span-1">
          <img src="@/assets/img/about/valores.jpg" alt="valores credil" />
        </div>
      </div>
    </section>

    <!-- INTRANET -->
    <section class="mb-4">
      <div class="container mt-12  px-4 md:px-20">
        <!-- CONVENIO 1 -->
        <div class="grid grid-cols-7 relative  bg-blue-700 ml-0 md:ml-32">
          <!-- Col 1 -->
          <div class="col-span-7 md:col-span-2 rounded-full  bg-white ml-0 md:-ml-32 my-2"
            style="width:320px; height:320px;">
            <img src="@/assets/img/about/intranet.png" alt="beneficios credil" class="p-5 " />
          </div>
          <!-- Col 2 -->
          <div class="col-span-7 md:col-span-4 py-16 px-3">
            <h4 class="text-white text-3xl mb-2 font-bold">INTRANET</h4>
            <div class="mb-4">
              <p class="text-white">
                Gestión integral de procesos en la institución.
              </p>
            </div>
            <span class="font-semibold  text-white mt-4 mb-4">CARACTERÍSTICAS:</span>
            <hr>
            <div class="flex mt-4">
              <div class="container ml-4">
                <ul class="list-disc px-5">
                  <li class="text-white">
                    Acceso restringido
                  </li>
                  <li class="text-white">
                    Colaboración
                  </li>
                  <li class="text-white">
                    Mejora de la productividad
                  </li>
                  <li class="text-white">
                    Centralización y gestión de recursos
                  </li>
                  <li class="text-white">
                    Organización y accesibilidad
                  </li>
                </ul>
              </div>
            </div>
            <div class="mb-4 mr-2 mt-4 ml-2">
              <a class="bg-white hover:bg-gray-200 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
                href="https://cooperativacredil.sharepoint.com/sites/IntranetCredil/">ACCEDER</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "About"
};
</script>
<style scoped>
/* Banner */
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}

.texto-banner {
  position: relative;
  top: 40%;
}

.img-banner-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}

.texto-principal-right {
  position: absolute;
  right: 0;
  left: 0;
  top: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
