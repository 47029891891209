var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative",staticStyle:{"width":"100%","height":"420px"}},[_c('slider',{ref:"slider",staticClass:"relative",attrs:{"options":_vm.options},on:{"slide":_vm.slide}},[_c('slideritem',{staticClass:"bg-blue-600 banner-slider",style:({
      'background-image':
        'url(' +
        require('@/assets/img/home/banners/v2/bannersRedes01.jpg') +
        ')'
    })}),_c('slideritem',{staticClass:"bg-blue-600 banner-slider",style:({
      'background-image':
        'url(' +
        require('@/assets/img/home/banners/v2/bannersRedes08.png') +
        ')'
    })}),_c('slideritem',{staticClass:"bg-blue-600 banner-slider",style:({
      'background-image':
        'url(' +
        require('@/assets/img/home/banners/v2/bannersRedes02.jpg') +
        ')'
    })}),_c('slideritem',{staticClass:"bg-blue-600 banner-slider",style:({
      'background-image':
        'url(' +
        require('@/assets/img/home/banners/v2/bannersRedes03.jpg') +
        ')'
    })}),_c('slideritem',{staticClass:"bg-blue-600 banner-slider",style:({
      'background-image':
        'url(' +
        require('@/assets/img/home/banners/v2/bannersRedes04.png') +
        ')'
    })}),_c('slideritem',{staticClass:"bg-blue-600 banner-slider",style:({
      'background-image':
        'url(' +
        require('@/assets/img/home/banners/v2/bannersRedes05.jpg') +
        ')'
    })}),_c('slideritem',{staticClass:"bg-blue-600 banner-slider",style:({
      'background-image':
        'url(' +
        require('@/assets/img/home/banners/v2/bannersRedes06.jpg') +
        ')'
    })})],1),_c('span',{staticClass:"arrows left",on:{"click":_vm.left}},[_c('font-awesome-icon',{staticClass:"text-white",attrs:{"icon":['fas', 'arrow-left']}})],1),_c('span',{staticClass:"arrows right",on:{"click":_vm.right}},[_c('font-awesome-icon',{staticClass:"text-white",attrs:{"icon":['fas', 'arrow-right']}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }